angular.module("app")
	.directive("search", function(){
		return {
			restrict: "A",
			transclude: true,
			scope: {
				search: "=",
				showSearch: "="
			},
			templateUrl: "/static/templates/directive/search-directive.html"
		};
	});